import Vue from 'vue'
import VueRouter from 'vue-router'
// import Dashboard from "../views/Dashboard"
import JobsToDo from "../views/JobsToDo"
import JobsComplete from "../views/JobsComplete"

Vue.use(VueRouter)

const routes = [
  // { path: '/', name: 'Dashboard', component: Dashboard },
  { path: '/', name: 'Jobs', component: JobsToDo },
  // { path: '/JobsToDo', name: 'Jobs',  component: JobsToDo },
  { path: '/JobsComplete', name: 'Completed Jobs',  component: JobsComplete }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
