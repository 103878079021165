<template>
    <div id="app" class="vh-100">
        <div id="blanker" v-if="$store.getters.uploading" class="d-flex justify-content-center align-items-center">
            <span class="uploadIcon text-center">
                <b-icon icon="arrow-clockwise" animation="spin" class="text-light" font-scale="4"></b-icon>
                <h2 class="text-light">Uploading, please wait...</h2>
            </span>
        </div>
        <vue-confirm-dialog></vue-confirm-dialog>
        <NetworkLogin v-if="$store.getters.logStatus == 0" />
        <SetPin v-if="$store.getters.logStatus == 1" />
        <LocalLogin v-else-if="$store.getters.logStatus == 2" />
        <div v-else-if="$store.getters.logStatus == 3">
            <Navbar />
            <router-view class="mt-3"></router-view>      
            <Settings />   
        </div>    
      
    </div>
</template>

<script>
import NetworkLogin from './components/NetworkLogin.vue'
import SetPin from './components/SetPin.vue'
import LocalLogin from './components/LocalLogin.vue'
import Navbar from './components/Navbar.vue'
import Settings from './components/Settings.vue'

export default {
    name: 'App',
    components: {
        NetworkLogin,
        SetPin,
        LocalLogin,
        Navbar,
        Settings
    }
}
</script> 

<style>
body{
    background-color: rgb(238, 238, 238)!important;
}
.card{
    background-color: rgb(207, 207, 207) !important;
    border-color: rgb(207, 207, 207) !important;
    color: rgb(173, 173, 173) !important;
    /* box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.3);
    -webkit-box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 2px 2px 3px 1px rgba(0,0,0,0.3); */
}
.alert{
    position: fixed !important;
    top: 1rem;
    left: 1rem;
    right: 1rem;
}
#blanker{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    background-color: rgba(41, 41, 41, 0.863);
}
</style>