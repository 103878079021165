import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persist-indexeddb"

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        user: {
            userId: 0,
            username: String,
            pin: '',
            logStatus: 0
        },
        jobsToDo: [],
        jobsCompleted: [],
        selectedJob: -1,
        // jobData: {
        //     selectedJobData: {},
        //     revertData: {}
        // },
        uploading: false,        
        added: 0,
        exists: 0,
        uploadCount: 0,
        apikey: 'beDlxF83pWfp88cTHy9L5lueI5is9LxAwk7K7w1EjO9Ix7XIEgg88dq52h858fj8'
    },
    mutations: {
        toggleUploading(state) {
            if(state.uploading === true){
                state.uploading = false
            }else{
                state.uploading = true
            }
        },
        setSelectedJobData(state, values) {
            state.selectedJobData = Object.assign({}, state.jobData.selectedJobData, values)
        },
        setRevertData(state, values) {
            state.revertData = Object.assign({}, state.jobData.revertData, values)
        },
        setUsername(state, username) {
            state.user.username = username
        },
        setUserId(state, userId) {
            state.user.userId = userId
        },
        setUserPin(state, pin) {
            state.user.pin = pin
        },
        setLogStatus(state, logStatus) {
            state.user.logStatus = logStatus
        },
        setSelectedJob(state, selectedJob) {
            state.selectedJob = selectedJob
        },
        addJobs(state, theJobs) {
            state.added = 0
            state.exists = 0
            var keys = Object.keys(theJobs);
            for (let i of keys) {
                theJobs[i].addressCorrect = 1
                theJobs[i].pcpType = null
                theJobs[i].pcpCabinetLocked = null
                theJobs[i].pcpLockAdded = null
                theJobs[i].pcpLockType = null
                theJobs[i].pcpSpaceAvailable = null
                theJobs[i].pcpClearOfRubbish = null
                theJobs[i].pcpDSLAMDistance = 0
                theJobs[i].pcpNearWater = null
                theJobs[i].pcpWorkCarriedOut = null
                theJobs[i].pcpAdditionalWork = null
                theJobs[i].pcpImageArray = []
                theJobs[i].dslamType = null
                theJobs[i].dslamCabinetLocked = null
                theJobs[i].dslamLockAdded = null
                theJobs[i].dslamLockType = null
                theJobs[i].dslamDSLAMDistance = 0
                theJobs[i].dslamWorkCarriedOut = null
                theJobs[i].dslamAdditionalWork = null
                theJobs[i].dslamImageArray = []
                theJobs[i].ebType = null
                theJobs[i].ebCabinetLocked = null
                theJobs[i].ebLockAdded = null
                theJobs[i].ebLockType = null
                theJobs[i].ebSpaceAvailable = null
                theJobs[i].ebClearOfRubbish = null
                theJobs[i].ebDSLAMDistance = 0
                theJobs[i].ebNearWater = null
                theJobs[i].ebWorkCarriedOut = null
                theJobs[i].ebAdditionalWork = null
                theJobs[i].ebImageArray = []
                theJobs[i].add1Type = null
                theJobs[i].add1CabinetLocked = null
                theJobs[i].add1LockAdded = null
                theJobs[i].add1LockType = null
                theJobs[i].add1SpaceAvailable = null
                theJobs[i].add1ClearOfRubbish = null
                theJobs[i].add1DSLAMDistance = 0
                theJobs[i].add1NearWater = null
                theJobs[i].add1WorkCarriedOut = null
                theJobs[i].add1AdditionalWork = null
                theJobs[i].add1ImageArray = []
                theJobs[i].add2Type = null
                theJobs[i].add2CabinetLocked = null
                theJobs[i].add2LockAdded = null
                theJobs[i].add2LockType = null
                theJobs[i].add2SpaceAvailable = null
                theJobs[i].add2ClearOfRubbish = null
                theJobs[i].add2DSLAMDistance = 0
                theJobs[i].add2NearWater = null
                theJobs[i].add2WorkCarriedOut = null
                theJobs[i].add2AdditionalWork = null
                theJobs[i].add2ImageArray = []
                theJobs[i].add3Type = null
                theJobs[i].add3CabinetLocked = null
                theJobs[i].add3LockAdded = null
                theJobs[i].add3LockType = null
                theJobs[i].add3SpaceAvailable = null
                theJobs[i].add3ClearOfRubbish = null
                theJobs[i].add3DSLAMDistance = 0
                theJobs[i].add3NearWater = null
                theJobs[i].add3WorkCarriedOut = null
                theJobs[i].add3AdditionalWork = null
                theJobs[i].add3ImageArray = []
                theJobs[i].isPink = null
                theJobs[i].isComplete = 0
                theJobs[i].pcpImageArray = []
                theJobs[i].hs1 = 0
                theJobs[i].hs2 = 0
                theJobs[i].hs3 = 0
                theJobs[i].hs4 = 0
                theJobs[i].hs5 = 0
                theJobs[i].hs6 = 0
                theJobs[i].hs7 = 0
                theJobs[i].hs8 = 0
                theJobs[i].hs9 = 0
                theJobs[i].hs10 = 0
                theJobs[i].hs11 = 0
                theJobs[i].hs12 = 0
                theJobs[i].hs13 = 0
                theJobs[i].hs14 = 0
                theJobs[i].hs15 = 0
                theJobs[i].hs16 = 0
                theJobs[i].hs17 = 0
                theJobs[i].hs18 = 0
                theJobs[i].hs19 = 0
                theJobs[i].hs20 = 0
                theJobs[i].hs21 = 0
                theJobs[i].hs22 = 0
                theJobs[i].hs23 = 0

                var index = state.jobsToDo.findIndex(x => x.jobId == theJobs[i].jobId);

                if(index === -1){
                    state.jobsToDo.push(theJobs[i])
                    state.added += 1
                }else{
                    state.exists += 1
                }
            }
            var alertString = ''
            if(state.added === 0 && state.exists === 0){
                alertString = "No jobs found to download"
            }else{
                if(state.added > 0){
                    alertString += state.added + ' new jobs added.\n\r'
                }
                if(state.exists > 0){
                    alertString += state.exists + ' jobs not added as they already exist.'
                }
            }
            alert(alertString)
        },
        setJobsToDo(state, data){
            state.jobsToDo = data
        },
        setJobByOrder(state, data){
            state.jobsToDo.filter(x => x.jobOrder == state.selectedJob)[0] = data
            console.log("saving data now")
        },
        saveJobData(state, data) {
            state.jobsToDo = [...state.jobsToDo.map(item => item.jobId !== data.jobId ? item : {...item, ...data})]
        },
        finishJob(state, data){   
            data.isComplete = 1  
            var d = new Date().toISOString().slice(0, 19).replace('T', ' ')
            data.dateFinished = d       
            state.jobsToDo = [...state.jobsToDo.map(item => item.jobId !== data.jobId ? item : {...item, ...data})]
        },
        makeJobTodo(state){
            state.jobData.selectedJobData.isComplete = 0
            this.saveJobData()
        },
        setUploadCount(state, value){
            state.uploadCount = value
        },
        deleteJob(state, id){
            console.log(id)
            state.jobsToDo = state.jobsToDo.filter(x => x.jobId != id)
        }
    },
    getters: {
        userId: state => state.user.userId,
        uploading: state => state.uploading,
        username: state => state.user.username,
        pin: state => state.user.pin,
        logStatus: state => state.user.logStatus,
        user: state => state.user,
        uploadCount: state => state.uploadCount,
        apikey: state => state.apikey,
        jobsToDo: state => state.jobsToDo.filter(job => job.isComplete === 0),
        jobsCompleted: state => state.jobsToDo.filter(job => job.isComplete === 1),
        selectedJob: state => state.selectedJob,
        selectedJobData: state => {
            if(state.jobsToDo != [] && state.selectedJob > 0){
                return state.jobsToDo.filter(job => job.jobId == state.selectedJob)[0]
            }else{
                return []
            }
        }
    },
    plugins: [createPersistedState()],
})