<template>
    <b-card class="mb-2" v-bind:class="{ active: job.jobId == selectedJob }">
        <b-card-text>
            <b-row @click="focus" class="d-flex align-items-stretch">
                <b-col sm="12">
                    <h4>{{ job.jobNo }}</h4>
                </b-col>
            </b-row>            
        </b-card-text>
    </b-card> 
</template>

<script>
export default {
    props: [
        'job',
        'selectedJob'
    ],
    methods: {
        focus: function(){
            this.$emit("focused", this.job.jobId)
        }
    }
}
</script>

<style scoped>
.active{
    background-color: white !important;
    color: black !important;
}
</style>