<template>
    <b-modal id="editJobModal" size="xxl" :title="'Edit Job ' + selectedJobData.jobNo" @close="cancelChanges" v-if="$store.getters.selectedJob > -1">  
        <b-card>
            <b-row>
                <b-col sm="4" class="mt-1">
                    <h6>Job No.: {{ selectedJobData.jobNo }}</h6>
                </b-col>
                <b-col sm="4" class="mt-1">
                    <h6>Cabinet: {{ selectedJobData.cabinet }}</h6>
                </b-col>
                <b-col sm="4" class="mt-1">
                    <h6>Exchange {{ selectedJobData.exchange }}</h6>
                </b-col>
                <b-col sm="12" class="mt-1">
                    <b-input-group prepend="Address">
                        <b-form-input v-model="selectedJobData.address" :disabled="selectedJobData.addressCorrect == 1"></b-form-input>
                    </b-input-group>
                </b-col>
                <!-- <b-col sm="12" class="mt-1">
                    <strong>Address:</strong> {{ selectedJobData.address }}
                </b-col> -->
                <b-col sm="4" class="mt-1">
                    <b-form inline>
                        <label for="addressCorrect">Address Correct?</label>
                        <b-form-select 
                            id="addressCorrect" 
                            class="ml-2"
                            :options="yesNoOpt" 
                            v-model="selectedJobData.addressCorrect"
                        ></b-form-select>
                    </b-form>
                </b-col>
                <b-col sm="12" class="mt-1">
                    <strong>Defect Notes:</strong><br>
                    <p>{{ selectedJobData.defectNotes }}</p>
                </b-col>

            </b-row>
        </b-card>

        <b-card no-body class="mt-3">
            <b-tabs card>

                <b-tab title="PCP Cabinet" :active="selectedJobData.jobType == 0">
                    <b-row>
                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="pcpType">PCP Cabinet</label>
                                <b-form-select 
                                    id="pcpType" 
                                    class="ml-2"
                                    v-model="selectedJobData.pcpType"
                                    :options="pcpCabs" 
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="pcpCabinetLocked">Cabinet Locked?</label>
                                <b-form-select 
                                    id="pcpCabinetLocked" 
                                    class="ml-2"
                                    :options="yesNoOpt" 
                                    v-model="selectedJobData.pcpCabinetLocked"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="pcpLockAdded">Lock Added?</label>
                                <b-form-select 
                                    id="pcpLockAdded" 
                                    class="ml-2"
                                    :options="yesNoOpt" 
                                    v-model="selectedJobData.pcpLockAdded"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="pcpLockType">Lock Type?</label>
                                <b-form-select 
                                    id="pcpLockType" 
                                    class="ml-2"
                                    :options="locks"  
                                    v-model="selectedJobData.pcpLockType"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form inline>
                                <label for="pcpSpaceAvailable">Cable Space Available?</label>
                                <b-form-select 
                                    id="pcpSpaceAvailable" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.pcpSpaceAvailable"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form inline>
                                <label for="pcpClearOfRubbish">Clear Of Rubbish?</label>
                                <b-form-select 
                                    id="pcpClearOfRubbish" 
                                    class="ml-2"
                                    :options="yesNoOpt"    
                                    v-model="selectedJobData.pcpClearOfRubbish"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="pcpDSLAMDistance">Associated Cabinet Distance {{ selectedJobData.pcpDSLAMDistance }}m</label>
                                <b-form-select 
                                    id="pcpDSLAMDistance" 
                                    class="ml-2"
                                    v-model="selectedJobData.pcpDSLAMDistance" 
                                    :options="distance"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="pcpNearWater">Is within 5m of natural or running water?</label>
                                <b-form-select 
                                    id="pcpNearWater" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.pcpNearWater"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="pcpWorkCarriedOut">Standard Work</label>
                                <b-form-textarea
                                    id="pcpWorkCarriedOut"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.pcpWorkCarriedOut"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="pcpAdditionalWork">Additional Work</label>
                                <b-form-textarea
                                    id="pcpAdditionalWork"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.pcpAdditionalWork"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form-file
                                ref="pcpFileInput"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                multiple
                                @input="processImage(selectedJobData.pcpImageArray, 'pcp')"
                            ></b-form-file>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col 
                            sm="2" 
                            v-for="image in selectedJobData.pcpImageArray" 
                            v-bind:key="image.name" 
                            class="text-center mt-3"
                        >
                            <img :src="image.base64" class="img-fluid" >
                            <p>{{ image.name }}</p>
                            <b-button 
                                variant="danger"
                                @click="deleteImage(selectedJobData.pcpImageArray, image.name, 'pcp')"
                            >
                            Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-tab>

                <b-tab title="DSLAM Cabinet" :active="selectedJobData.jobType == 1">                        
                    <b-row>
                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="dslamType">DSLAM Cabinet</label>
                                <b-form-select 
                                    id="dslamType" 
                                    class="ml-2"
                                    :options="dslamCabs"
                                    v-model="selectedJobData.dslamType"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="dslamCabinetLocked">Cabinet Locked?</label>
                                <b-form-select 
                                    id="dslamCabinetLocked" 
                                    class="ml-2"
                                    :options="yesNoOpt" 
                                    v-model="selectedJobData.dslamCabinetLocked"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="dslamLockAdded">Lock Added?</label>
                                <b-form-select 
                                    id="dslamLockAdded" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.dslamLockAdded"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="dslamLockType">Lock Type?</label>                                    
                                <b-form-select 
                                    id="dslamLockType" 
                                    class="ml-2"
                                    :options="locks" 
                                    v-model="selectedJobData.dslamLockType"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="dslamDSLAMDistance">Associated Cabinet Distance {{ selectedJobData.dslamDSLAMDistance }}m</label>
                                <b-form-select 
                                    id="dslamDSLAMDistance" 
                                    class="ml-2"
                                    v-model="selectedJobData.dslamDSLAMDistance" 
                                    :options="distance"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="dslamWorkCarriedOut">Standard Work</label>
                                <b-form-textarea
                                    id="dslamWorkCarriedOut"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.dslamWorkCarriedOut"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="dslamAdditionalWork">Additional Work</label>
                                <b-form-textarea
                                    id="dslamAdditionalWork"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.dslamAdditionalWork"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form-file
                                ref="dslamFileInput"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                multiple
                                @input="processImage(selectedJobData.dslamImageArray, 'dslam')"
                            ></b-form-file>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col 
                            sm="2" 
                            v-for="image in selectedJobData.dslamImageArray" 
                            :key="image.name" 
                            class="text-center mt-3"
                        >
                            <img :src="image.base64" class="img-fluid" >
                            <p>{{ image.name }}</p>
                            <b-button 
                                variant="danger"
                                @click="deleteImage(selectedJobData.dslamImageArray, image.name, 'dslam')"
                            >
                            Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-tab>
                
                <b-tab title="Earth Bonding" :active="selectedJobData.jobType == 2">
                    <b-row>
                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="ebType">Cabinet</label>
                                <b-form-select 
                                    id="ebType" 
                                    class="ml-2"
                                    :options="allCabs"
                                    v-model="selectedJobData.ebType"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="ebCabinetLocked">Cabinet Locked?</label>
                                <b-form-select 
                                    id="ebCabinetLocked" 
                                    class="ml-2"
                                    :options="yesNoOpt"   
                                    v-model="selectedJobData.ebCabinetLocked"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="ebLockAdded">Lock Added?</label>
                                <b-form-select 
                                    id="ebLockAdded" 
                                    class="ml-2"
                                    :options="yesNoOpt"   
                                    v-model="selectedJobData.ebLockAdded"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="ebLockType">Lock Type?</label>
                                <b-form-select 
                                    id="ebLockType" 
                                    class="ml-2"
                                    :options="locks" 
                                    v-model="selectedJobData.ebLockType"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form inline>
                                <label for="ebSpaceAvailable">Cable Space Available?</label>
                                <b-form-select 
                                    id="ebSpaceAvailable" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.ebSpaceAvailable"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form inline>
                                <label for="ebClearOfRubbish">Clear Of Rubbish?</label>
                                <b-form-select 
                                    id="ebClearOfRubbish" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.ebClearOfRubbish"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="ebDSLAMDistance">Associated Cabinet Distance {{ selectedJobData.ebDSLAMDistance }}m</label>
                                <b-form-select 
                                    id="ebDSLAMDistance" 
                                    class="ml-2"
                                    v-model="selectedJobData.ebDSLAMDistance" 
                                    :options="distance"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="ebNearWater">Is within 5m of natural or running water?</label>
                                <b-form-select 
                                    id="ebNearWater" 
                                    class="ml-2"
                                    :options="yesNoOpt"   
                                    v-model="selectedJobData.ebNearWater"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="ebWorkCarriedOut">Standard Work</label>
                                <b-form-textarea
                                    id="ebWorkCarriedOut"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.ebWorkCarriedOut"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="ebAdditionalWork">Additional Work</label>
                                <b-form-textarea
                                    id="ebAdditionalWork"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.ebAdditionalWork"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form-file
                                ref="ebFileInput"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                multiple
                                @input="processImage(selectedJobData.ebImageArray, 'eb')"
                            ></b-form-file>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col 
                            sm="2" 
                            v-for="image in selectedJobData.ebImageArray" 
                            :key="image.name" 
                            class="text-center mt-3"
                        >
                            <img :src="image.base64" class="img-fluid" >
                            <p>{{ image.name }}</p>
                            <b-button 
                                variant="danger"
                                @click="deleteImage(selectedJobData.ebImageArray, image.name, 'eb')"
                            >
                            Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-tab>
                
                <b-tab title="Add 1">
                    <b-row>
                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="add1Type">Cabinet</label>
                                <b-form-select 
                                    id="add1Type" 
                                    class="ml-2"
                                    :options="allCabs"
                                    v-model="selectedJobData.add1Type"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="add1CabinetLocked">Cabinet Locked?</label>
                                <b-form-select 
                                    id="add1CabinetLocked" 
                                    class="ml-2"
                                    :options="yesNoOpt"    
                                    v-model="selectedJobData.add1CabinetLocked"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="add1LockAdded">Lock Added?</label>
                                <b-form-select 
                                    id="add1LockAdded" 
                                    class="ml-2"
                                    :options="yesNoOpt" 
                                    v-model="selectedJobData.add1LockAdded"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="add1LockType">Lock Type?</label>
                                <b-form-select 
                                    id="add1LockType" 
                                    class="ml-2"
                                    :options="locks"   
                                    v-model="selectedJobData.add1LockType"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form inline>
                                <label for="add1SpaceAvailable">Cable Space Available?</label>
                                <b-form-select 
                                    id="add1SpaceAvailable" 
                                    class="ml-2"
                                    :options="yesNoOpt"    
                                    v-model="selectedJobData.add1SpaceAvailable"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form inline>
                                <label for="add1ClearOfRubbish">Clear Of Rubbish?</label>
                                <b-form-select 
                                    id="add1ClearOfRubbish" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.add1ClearOfRubbish"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="add1DSLAMDistance">Associated Cabinet Distance {{ selectedJobData.add1DSLAMDistance }}m</label>
                                <b-form-select 
                                    id="add1DSLAMDistance" 
                                    class="ml-2"
                                    v-model="selectedJobData.add1DSLAMDistance" 
                                    :options="distance"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="add1NearWater">Is within 5m of natural or running water?</label>
                                <b-form-select 
                                    id="add1NearWater" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.add1NearWater"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="add1WorkCarriedOut">Standard Work</label>
                                <b-form-textarea
                                    id="add1WorkCarriedOut"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.add1WorkCarriedOut"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="add1AdditionalWork">Additional Work</label>
                                <b-form-textarea
                                    id="add1AdditionalWork"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.add1AdditionalWork"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form-file
                                ref="add1FileInput"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                multiple
                                @input="processImage(selectedJobData.add1ImageArray, 'add1')"
                            ></b-form-file>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col 
                            sm="2" 
                            v-for="image in selectedJobData.add1ImageArray" 
                            :key="image.name" 
                            class="text-center mt-3"
                        >
                            <img :src="image.base64" class="img-fluid" >
                            <p>{{ image.name }}</p>
                            <b-button 
                                variant="danger"
                                @click="deleteImage(selectedJobData.add1ImageArray, image.name, 'add1')"
                            >
                            Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-tab>
                
                <b-tab title="Add 2">
                    <b-row>
                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="add2Type">Cabinet</label>
                                <b-form-select 
                                    id="add2Type" 
                                    class="ml-2"
                                    :options="allCabs"
                                    v-model="selectedJobData.add2Type"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="add2CabinetLocked">Cabinet Locked?</label>
                                <b-form-select 
                                    id="add2CabinetLocked" 
                                    class="ml-2"
                                    :options="yesNoOpt"   
                                    v-model="selectedJobData.add2CabinetLocked"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="add2LockAdded">Lock Added?</label>
                                <b-form-select 
                                    id="add2LockAdded" 
                                    class="ml-2"
                                    :options="yesNoOpt" 
                                    v-model="selectedJobData.add2LockAdded"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="add2LockType">Lock Type?</label>
                                <b-form-select 
                                    id="add2LockType" 
                                    class="ml-2"
                                    :options="locks" 
                                    v-model="selectedJobData.add2LockType"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form inline>
                                <label for="add2SpaceAvailable">Cable Space Available?</label>
                                <b-form-select 
                                    id="add2SpaceAvailable" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.add2SpaceAvailable"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form inline>
                                <label for="add2ClearOfRubbish">Clear Of Rubbish?</label>
                                <b-form-select 
                                    id="add2ClearOfRubbish" 
                                    class="ml-2"
                                    :options="yesNoOpt"   
                                    v-model="selectedJobData.add2ClearOfRubbish"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="add2DSLAMDistance">Associated Cabinet Distance {{ selectedJobData.add2DSLAMDistance }}m</label>
                                <b-form-select 
                                    id="add2DSLAMDistance" 
                                    class="ml-2"
                                    v-model="selectedJobData.add2DSLAMDistance" 
                                    :options="distance"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="add2NearWater">Is within 5m of natural or running water?</label>
                                <b-form-select 
                                    id="add2NearWater" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.add2NearWater"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="add2WorkCarriedOut">Standard Work</label>
                                <b-form-textarea
                                    id="add2WorkCarriedOut"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.add2WorkCarriedOut"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="add2AdditionalWork">Additional Work</label>
                                <b-form-textarea
                                    id="add2AdditionalWork"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.add2AdditionalWork"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form-file
                                ref="add2FileInput"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                multiple
                                @input="processImage(selectedJobData.add2ImageArray, 'add2')"
                            ></b-form-file>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col 
                            sm="2" 
                            v-for="image in selectedJobData.add2ImageArray" 
                            :key="image.name" 
                            class="text-center mt-3"
                        >
                            <img :src="image.base64" class="img-fluid" >
                            <p>{{ image.name }}</p>
                            <b-button 
                                variant="danger"
                                @click="deleteImage(selectedJobData.add2ImageArray, image.name, 'add2')"
                            >
                            Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-tab>
                
                <b-tab title="Add 3">
                    <b-row>
                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="add3Type">Cabinet</label>
                                <b-form-select 
                                    id="add3Type" 
                                    class="ml-2"
                                    :options="allCabs"
                                    v-model="selectedJobData.add3Type"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="add3CabinetLocked">Cabinet Locked?</label>
                                <b-form-select 
                                    id="add3CabinetLocked" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.add3CabinetLocked"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="add3LockAdded">Lock Added?</label>
                                <b-form-select 
                                    id="add3LockAdded" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.add3LockAdded"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <b-form inline>
                                <label for="add3LockType">Lock Type?</label>
                                <b-form-select 
                                    id="add3LockType" 
                                    class="ml-2"
                                    :options="locks"  
                                    v-model="selectedJobData.add3LockType"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form inline>
                                <label for="add3SpaceAvailable">Cable Space Available?</label>
                                <b-form-select 
                                    id="add3SpaceAvailable" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.add3SpaceAvailable"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form inline>
                                <label for="add3ClearOfRubbish">Clear Of Rubbish?</label>
                                <b-form-select 
                                    id="add3ClearOfRubbish" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.add3ClearOfRubbish"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="add3DSLAMDistance">Associated Cabinet Distance {{ selectedJobData.add3DSLAMDistance }}m</label>
                                <b-form-select 
                                    id="add3DSLAMDistance" 
                                    class="ml-2"
                                    v-model="selectedJobData.add3DSLAMDistance" 
                                    :options="distance"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form inline>
                                <label for="add3NearWater">Is within 5m of natural or running water?</label>
                                <b-form-select 
                                    id="add3NearWater" 
                                    class="ml-2"
                                    :options="yesNoOpt"  
                                    v-model="selectedJobData.add3NearWater"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="add3WorkCarriedOut">Standard Work</label>
                                <b-form-textarea
                                    id="add3WorkCarriedOut"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.add3WorkCarriedOut"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="6" class="mt-1">
                            <b-form>
                                <label for="add3AdditionalWork">Additional Work</label>
                                <b-form-textarea
                                    id="add3AdditionalWork"
                                    rows="3"
                                    max-rows="6"
                                    v-model="selectedJobData.add3AdditionalWork"
                                ></b-form-textarea>
                            </b-form>
                        </b-col>

                        <b-col sm="12" class="mt-1">
                            <b-form-file
                                ref="add3FileInput"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                multiple
                                @input="processImage(selectedJobData.add3ImageArray, 'add3')"
                            ></b-form-file>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col 
                            sm="2" 
                            v-for="image in selectedJobData.add3ImageArray" 
                            :key="image" 
                            class="text-center mt-3"
                        >
                            <v-img :src="image.base64" class="img-fluid" />
                            <p>{{ image.name }}</p>
                            <b-button 
                                variant="danger"
                                @click="deleteImage(selectedJobData.add3ImageArray, image.name, 'add3')"
                            >
                            Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-tab>
                
                <b-tab title="Health and Safety">
                    <b-row>
                        <b-col sm="12" class="mt-1">
                            <h4>ITEMS TO BE CHECKED BY THE COMPETENT PERSON</h4>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs1">Are all relevant Health Safety & Environmental Doc's on site</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs1" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs1"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs16">Has a PCP survey template been completed</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs16" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs16"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs2">Is the work area free from slipping and tripping hazards</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs2" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs2"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs17">Has a manhole survey template been completed</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs17" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs17"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs3">Has a daily check taken place on all equipment that is to be used</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs3" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs3"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs18">Calibrated in date GDU available and in good working order</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs18" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs18"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs4">Is the area free from risk for the general public</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs4" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs4"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs19">Operative trained in the use GDU</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs19" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs19"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs5">Is all the signing, lighting and guarding as per the code of practice</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs5" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs5"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs20">Is Air blower in place for manhole entry</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs20" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs20"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs6">At least one gang member holds the required NRASWA unit for the work activity</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs6" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs6"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs21">Have pre-stat photographs been taken</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs21" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs21"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs7">Is all the relevant PPE to be used in good condition</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs7" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs7"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs22">Have completion photographs been taken</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs22" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs22"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs8">Are all relevant control measures in place as detailed in the Generic taskbased risk assessments</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs8" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs8"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs23">Has correct SLG been left in place where required</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs23" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs23"
                                ></b-form-select>
                            </b-form>
                        </b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs9">Is the material storage safe and the site security adequate</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs9" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs9"
                                ></b-form-select>
                            </b-form>
                        </b-col>
                        <b-col sm="6"></b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs10">Do operatives on site have the appropriate accreditation</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs10" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs10"
                                ></b-form-select>
                            </b-form>
                        </b-col>
                        <b-col sm="6"></b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs11">Is an adequate protection from sparks or fragments from abrasive wheels</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs11" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs11"
                                ></b-form-select>
                            </b-form>
                        </b-col>
                        <b-col sm="6"></b-col>

                        <b-col sm="4" class="mt-1">
                            <label for="hs12">Are there any specific risks associated with this site not covered in the generic tasks base risk assessment</label>
                        </b-col>
                        <b-col sm="2" class="mt-1">
                            <b-form>
                                <b-form-select 
                                    id="hs12" 
                                    :options="hsOpt"
                                    v-model="selectedJobData.hs12"
                                ></b-form-select>
                            </b-form>
                        </b-col>
                        <b-col sm="6"></b-col>

                    </b-row>
                </b-tab>

            </b-tabs>
        </b-card>

        <template #modal-footer>
            <div class="w-100">
                <b-form class="float-left" inline>
                    <label for="isPink">Is this a pink?</label>
                    <b-form-select 
                        id="isPink" 
                        class="ml-2"
                        :options="yesNoOpt" 
                        v-model="selectedJobData.isPink"
                    ></b-form-select>
                </b-form>
                <div class="float-right">
                    <b-button
                        variant="secondary"
                        class="mr-2"
                        @click="cancelChanges"
                    >
                        Cancel
                    </b-button>
                    <b-button
                        variant="primary"
                        class="mr-2"
                        @click="undoChanges"
                    >
                        Revert Data
                    </b-button>
                    <b-button
                        variant="info"
                        class="mr-2"
                        @click="saveChanges"
                    >
                        Save and Close
                    </b-button>
                    <b-button
                        variant="success"
                        class="mr-2"
                        @click="finish"
                    >
                        Finish
                    </b-button>
                </div>
            </div>
        </template>
        
    </b-modal>
</template>

<script>
// import { mapState } from "vuex";
export default {
    props: [
        'selectedJob'
    ],
    data: function() {
        return {
            dslamDistance: '1',
            selectedJobData: {},
            revertData: {},
            yesNoOpt: [
                { text: 'Please Choose', value: null },
                { text: 'Yes', value: '1' },
                { text: 'No', value: '0' }
            ],
            yesNoOpt2: [
                { text: 'Yes', value: '1' },
                { text: 'No', value: '0' }
            ],
            hsOpt: [
                { text: 'Please Choose...', value: '0' },
                { text: 'Yes', value: '1' },
                { text: 'No', value: '2' },
                { text: 'N/A', value: '3' }
            ],
            pcpCabs: [
                { text: 'Please Choose...', value: null },
                { text: '1A', value: '1A' },
                { text: '2A', value: '2A' },
                { text: '3A', value: '3A' },
                { text: '4', value: '4' },
                { text: '4A', value: '4A' },
                { text: '5', value: '5' },
                { text: '6', value: '6' },
                { text: '6A', value: '6A' },
                { text: '6B', value: '6B' },
                { text: '6C', value: '6C' },
                { text: 'H/S 6', value: 'H/S 6' },
                { text: '7', value: '7' },
                { text: '7B', value: '7B' },
                { text: '7C', value: '7C' },
                { text: 'H/S 7', value: 'H/S 7' },
                { text: 'Pillar', value: 'Pillar' }
            ],
            dslamCabs: [
                { text: 'Please Choose...', value: null },
                { text: 'Huawei 288', value: 'Huawei 288' },
                { text: 'Huawei 96', value: 'Huawei 96' },
                { text: 'Huawei All In One', value: 'Huawei All In One' },
                { text: 'ECI 128/256', value: 'ECI 128/256' }
            ],
            allCabs: [
                { text: 'Please Choose...', value: null },
                { text: '1A', value: '1A' },
                { text: '2A', value: '2A' },
                { text: '3A', value: '3A' },
                { text: '4', value: '4' },
                { text: '4A', value: '4A' },
                { text: '5', value: '5' },
                { text: '6', value: '6' },
                { text: '6A', value: '6A' },
                { text: '6B', value: '6B' },
                { text: '6C', value: '6C' },
                { text: 'H/S 6', value: 'H/S 6' },
                { text: '7', value: '7' },
                { text: '7B', value: '7B' },
                { text: '7C', value: '7C' },
                { text: 'H/S 7', value: 'H/S 7' },
                { text: 'Huawei 288', value: 'Huawei 288' },
                { text: 'Huawei 96', value: 'Huawei 96' },
                { text: 'Huawei All In One', value: 'Huawei All In One' },
                { text: 'ECI 128/256', value: 'ECI 128/256' }
            ],
            locks: [
                { text: 'Please Choose...', value: null},
                { text: 'JA1B', value: 'JA1B' },
                { text: 'CA1B', value: 'CA1B' },
                { text: 'DA1B', value: 'DA1B' },
                { text: 'MA1B', value: 'MA1B' },
                { text: 'MB1B', value: 'MB1B' },
                { text: 'MC1B', value: 'MC1B' },
                { text: 'KA1B', value: 'KA1B' },
                { text: 'KB1B', value: 'KB1B' },
                { text: 'KC1B', value: 'KC1B' },
                { text: 'HA1B', value: 'HA1B' },
                { text: 'HB1B', value: 'HB1B' },
                { text: 'HC1B', value: 'HC1B' },
                { text: 'WA1B', value: 'WA1B' },
                { text: 'WB1B', value: 'WB1B' },
                { text: 'WC1B', value: 'JA1B' },
                { text: 'GA3B', value: 'GA3B' },
                { text: 'GB3B', value: 'GB3B' },
                { text: 'GC3B', value: 'JA1B' },
                { text: 'CA1D', value: 'CA1D' },
                { text: 'HA1D', value: 'HA1D' },
                { text: 'N/A', value: 'N/A' }
            ],
            distance: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100 ]
        }
    },
    methods: {
        cancelChanges: function(){
            console.log("Cancelling changes and closing")
            this.$store.commit('setSelectedJob', -1)            
            this.selectedJobData = {}
            this.revertData = {}
            this.$bvModal.hide("editJobModal")         
        },
        undoChanges: function(){
            console.log("reverting")
            this.selectedJobData = Object.assign({}, this.revertData)
        },
        saveChanges: function(){
            console.log("Saving changes and closing")
            this.$store.commit("saveJobData", this.selectedJobData)
            this.$store.commit('setSelectedJob', -1)          
            this.selectedJobData = {}
            this.revertData = {}
            this.$bvModal.hide("editJobModal")
        },
        finish: function(){
            if(this.selectedJobData.hs1 == 0 || this.selectedJobData.hs2 == 0 || this.selectedJobData.hs3 == 0 || this.selectedJobData.hs4 == 0 || this.selectedJobData.hs5 == 0 || this.selectedJobData.hs6 == 0 || this.selectedJobData.hs7 == 0 || this.selectedJobData.hs8 == 0 || this.selectedJobData.hs9 == 0 || this.selectedJobData.hs10 == 0 || this.selectedJobData.hs11 == 0 || this.selectedJobData.hs12 == 0 || this.selectedJobData.hs16 == 0 || this.selectedJobData.hs17 == 0 || this.selectedJobData.hs18 == 0 || this.selectedJobData.hs19 == 0 || this.selectedJobData.hs20 == 0 || this.selectedJobData.hs21 == 0 || this.selectedJobData.hs22 == 0 || this.selectedJobData.hs23 == 0){
                alert("Please complete the Health and Safety panel.")
            }else if(this.selectedJobData.isPink == null){
                alert("Please set the 'Is this a pink?' selection.")
            }else{
                console.log("Finish")
                this.$store.commit("finishJob", this.selectedJobData)
                this.$store.commit('setSelectedJob', -1)          
                this.selectedJobData = {}
                this.revertData = {}
                this.$bvModal.hide("editJobModal")
            }
        },
        processImage (array, cabType) {  
            var image          
            switch(cabType) {
                case 'pcp':
                    image = this.$refs.pcpFileInput
                    break;
                case 'dslam':
                    image = this.$refs.dslamFileInput
                    break;
                case 'eb':
                    image = this.$refs.ebFileInput
                    break;
                case 'add1':
                    image = this.$refs.add1FileInput
                    break;
                case 'add2':
                    image = this.$refs.add2FileInput
                    break;
                case 'add3':
                    image = this.$refs.add3FileInput
                    break;
            }
            
            image.files.forEach(async input => {
                var data = JSON.parse(await this.resizeImageJpg(input, 1920, 1080))
                array.push(data)
            })
        },
        deleteImage(array, name, cabType){
            console.log("Deleting image")
            var filteredArray = array.filter(function(e) {
                return e.name !== name
            })
            if(confirm("Image will be removed, are you sure?")){
                switch(cabType) {
                    case 'pcp':
                        this.selectedJobData.pcpImageArray = filteredArray
                        break;
                    case 'dslam':
                        this.selectedJobData.dslamImageArray = filteredArray
                        break;
                    case 'eb':
                        this.selectedJobData.ebImageArray = filteredArray
                        break;
                    case 'add1':
                        this.selectedJobData.add1ImageArray = filteredArray
                        break;
                    case 'add2':
                        this.selectedJobData.add2ImageArray = filteredArray
                        break;
                    case 'add3':
                        this.selectedJobData.add3ImageArray = filteredArray
                        break;
                }
            }
            
        },
        resizeImageJpg(input){
            return new Promise(function (resolve, reject){
                
                var originalname = input['name'];
                var extension = originalname.substring(originalname.lastIndexOf('.') + 1);
                var newname = new Date().valueOf() + '-' + originalname.replace('.' + extension, '.jpg',);
                var img = document.createElement("img");
                var reader = new FileReader();
            
                reader.onload = function(e) {
                    img.src = e.target.result;
                    img.onload = function () {
                        var canvas = document.createElement("canvas");
                        var ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0);
                        var MAX_WIDTH = 700;
                        var MAX_HEIGHT = 700;
                        var width = this.width;
                        var height = this.height;
                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);
                        var data = {}
                        data.base64 = canvas.toDataURL("image/jpeg", 0.3);
                        data.name = newname
                        var jsonString = JSON.stringify(data)
                        resolve(jsonString)
                    }
                    input.onerror= function() {
                        reject(JSON.parse(['Invalid file type: '+ input.type]))
                    }
                }
                reader.readAsDataURL(input);
            })
        }
    },
    watch: {
        selectedJob(newValue, oldValue){
            if(newValue !== oldValue){
                this.selectedJobData = Object.assign({}, this.$store.getters.selectedJobData)
                this.revertData = Object.assign({}, this.$store.getters.selectedJobData)
            }
        }
    }
}
</script>

<style scoped>
.card{
    background-color: white !important;
    color: black !important;
}
</style>