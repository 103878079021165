<template>
    <b-container>
        <b-row class="vh-100 d-flex justify-content-center align-items-center">
            <b-col sm="4">
                <h1>Network Login</h1>
                <div class="form-group mt-3">
                    <label for="username"><h4>Username</h4></label>
                    <input @input="changeUsername" name="username" class="form-control" id="username">
                </div>
                <div class="form-group mt-3">
                    <label for="password"><h4>Password</h4></label>
                    <input @input="changePassword" name="password" type="password" class="form-control" id="password">
                </div>
                <div class="form-group mt-3">
                    <button v-bind:disabled="isDisable" @click="loginUser" class="btn btn-primary btn-block">Login</button>
                </div>
                <div v-if="errorMessage != ''" class="alert alert-danger text-center">
                    {{ errorMessage }}
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import axios from 'axios'

export default {
    data: function() {
        return {
            username: '',
            password: '',
            errorMessage: ''
        }
    },
    methods: {
        changeUsername: function(event) {
            this.username = event.target.value
        },
        changePassword: function(event) {
            this.password = event.target.value
        },
        loginUser: function() {
            axios({
                method: 'POST',
                url: 'https://portal.mocoltd.co.uk/api/login/',    
                headers: {},
                data: {
                    inputUsername: this.username,
                    inputPassword: this.password,
                    apikey: this.$store.getters.apikey
                }
            }).then(response =>(this.processLogin(response.data)))      
        },
        processLogin: function(data){
            console.log(data)
            if(data.status == 'success'){
                this.$store.commit('setUserId', data.data.id)
                this.$store.commit('setUsername', data.data.username)
                console.log(this.$store.getters.username)
                this.$store.commit('setLogStatus', 1)
                //open setpin
            }else{
                this.errorMessage = data.detail
            }
        }
    },
    computed: {
        isDisable: function() {
            if(this.username.length > 0 && this.password.length > 0){
                return false
            }else{
                return true
                
            }
        }
    }
}
</script>

<style>
.btn-block{
    width: 100%;
}
</style>