<template>
    <div>    
        <b-container fluid>
            <h1>Jobs</h1>
            <b-row class="mt-3" v-if="$store.getters.jobsToDo != []">
                <b-col sm="6">
                    <b-col sm="12" v-for="job in orderedJobs" :key="job.jobNo">
                        <JobCard v-bind:job="job" v-bind:selectedJob="$store.getters.selectedJob" v-on:focused="setFocused"></JobCard>
                    </b-col>
                </b-col>
                <b-col sm="6" v-if="$store.getters.jobsToDo != []">
                    <JobDetails page="toDo" :selectedJob="$store.getters.selectedJob"></JobDetails>
                </b-col>
            </b-row>
        </b-container>
        <JobEditModal :selectedJob="$store.getters.selectedJob"></JobEditModal>
    </div>
</template>

<script>
import _ from 'lodash';
import JobCard from '../components/JobCard.vue'
import JobDetails from '../components/JobDetails.vue'
import JobEditModal from '../components/JobEditModal.vue'

export default {
    components: {
        JobCard,
        JobDetails,
        JobEditModal
    },
    methods: {
        setFocused: function(selectedJob){
            this.$store.commit('setSelectedJob', selectedJob)
        },
        resetSelectedJobData(variable) {
            console.log("resetting")
            this.thisSelectedJobData = variable
            console.log(this.thisSelectedJobData)
        }

    },
    computed: {
        orderedJobs: function () {
            return _.orderBy(this.$store.getters.jobsToDo, 'jobNo')
        }
    },
    mounted() {
        this.$store.commit('setSelectedJob', -1)
    }
}
</script>

<style>
.modal-xxl{
    max-width: calc(100% - 16px)!important;
    width: calc(100% - 16px)!important;
}
@media (min-width: 768px){
    .modal-xxl{
        max-width: calc(100% - 56px)!important;
        width: calc(100% - 56px)!important;
    }
}
</style>