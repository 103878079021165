import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueConfirmDialog from 'vue-confirm-dialog'
import './registerServiceWorker'
import VOffline from 'v-offline';

Vue.use(VOffline);

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.onbeforeunload = function(){
  if(store.getters.logStatus == 3){
    store.commit('setLogStatus', 2)
  }
}
