<template>
    <div>
        <b-card class="mb-2" v-if="$store.getters.selectedJob > 0">
            <b-card-text>
                <h4>JobDetails</h4>
                <b-row>
                    <b-col sm="12">
                        <h4>{{ selectedJobData.jobNo }}</h4>
                        <p><strong>Exchange:</strong> {{ selectedJobData.exchange }}</p>
                        <p><strong>Cabinet:</strong> {{ selectedJobData.cabinet }}</p>
                        <p><strong>Address:</strong> {{ selectedJobData.address }}</p>
                        <p><strong>Job Type:</strong> {{ typeArray[selectedJobData.jobType] }}</p>
                        <p><strong>Defect Notes:</strong> {{ selectedJobData.defectNotes }}</p>
                    </b-col>
                    <b-col sm="12" v-if="page == 'toDo'">
                        <b-row>
                            <b-col sm="4">                    
                                <button class="btn btn-block btn-warning" @click="openMap"><b-icon-map></b-icon-map> View Map</button>
                            </b-col>
                            <b-col sm="4">
                                <button class="btn btn-block btn-success" @click="editJob"><b-icon-pencil-square></b-icon-pencil-square> Edit Job</button>
                            </b-col>
                            <b-col sm="4">                    
                                <button class="btn btn-block btn-danger" @click="deleteJob"><b-icon-trash></b-icon-trash> Delete Job</button>
                            </b-col>                    
                        </b-row>
                    </b-col>
                    <b-col sm="12" v-if="page == 'completed'">
                        <b-row>
                            <b-col sm="6">                    
                                <button class="btn btn-block btn-warning" @click="openMap"><b-icon-map></b-icon-map> View Map</button>
                            </b-col>
                            <b-col sm="6">
                                <button class="btn btn-block btn-danger" @click="makeToDo" v-if="page == 'completed'"><b-icon-pencil-square></b-icon-pencil-square> Return to To Do</button>
                            </b-col>            
                        </b-row>
                    </b-col>
                    
                </b-row>            
            </b-card-text>
        </b-card> 
    </div>
</template>

<script>

export default {  
    data: function() {
        return {
            selectedJobData: {},
            theJobId: this.selectedJob,
            typeArray: ['PCP', 'DSLAM', 'EARTH BONDING']
        }
    },
    props: [
        'page',
        'selectedJob'
    ],  
    methods: {
        openMap: function(){
            var lat = this.selectedJobData.latitude
            var long = this.selectedJobData.longitude
            window.open('http://maps.google.com/maps?zoom=19&q=' + lat + ', ' + long) // for google maps app
            // window.open('http://maps.google.com/maps?zoom=19&q=loc:' + lat + '+' + long, '_blank') // for google maps web
            // window.open('http://maps.apple.com/?ll=' + lat + ',' + long + '&z=15.0&q=' + exchange + ' ' + cabinet) // for apple maps
        },
        editJob: function(){
            this.$bvModal.show('editJobModal')
        },
        makeToDo: function(){
            this.selectedJobData.isComplete = 0
            console.log(this.selectedJobData)
            this.$store.commit("saveJobData", this.selectedJobData)
            this.$store.commit('setSelectedJob', -1)
        },
        deleteJob: function(){
            this.$confirm(
                {
                    message: `WARNING: All data and images for this app will be deleted. Are you sure you wish to delete this job?`,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            this.$store.commit("deleteJob", this.selectedJobData.jobId)
                            this.$store.commit('setSelectedJob', -1)          
                            this.selectedJobData = {}
                            this.revertData = {}
                        }
                    }
                }
            )
            
        }
    },
    watch: {
        selectedJob(newValue, oldValue){
            if(newValue !== oldValue){
                console.log("job changed")
                this.selectedJobData = Object.assign({}, this.$store.getters.selectedJobData)
            }
        }
    }
}
</script>

<style scoped>
.card{
    background-color: white !important;
    color: black !important;
}
</style>

// ids

// addressCorrect
// pcpType
// pcpCabinetLocked
// pcpLockAdded
// pcpLockType
// pcpSpaceAvailable
// pcpClearOfRubbish
// pcpDSLAMDistance
// pcpNearWater
// pcpWorkCarriedOut
// dslamType
// dslamCabinetLocked
// dslamLockAdded
// dslamLockType
// dslamWorkCarriedOut
// ebType
// ebCabinetLocked
// ebLockAdded
// ebLockType
// ebSpaceAvailable
// ebClearOfRubbish
// ebDSLAMDistance
// ebNearWater
// ebWorkCarriedOut
// add1Type
// add1CabinetLocked
// add1LockAdded
// add1LockType
// add1SpaceAvailable
// add1ClearOfRubbish
// add1DSLAMDistance
// add1NearWater
// add1WorkCarriedOut
// add2Type
// add2CabinetLocked
// add2LockAdded
// add2LockType
// add2SpaceAvailable
// add2ClearOfRubbish
// add2DSLAMDistance
// add2NearWater
// add2WorkCarriedOut
// add3Type
// add3CabinetLocked
// add3LockAdded
// add3LockType
// add3SpaceAvailable
// add3ClearOfRubbish
// add3DSLAMDistance
// add3NearWater
// add3WorkCarriedOut
// isPink